import React from 'react';
import Layout from '../../components/Layout/Layout';
import * as style from './ydelseTemplate.module.scss';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';

const ydelseTemplate = ({pageContext}) => {

  const image = pageContext.featuredImage.node.gatsbyImage;


  return (
    <Layout>
      <div className={style.wrapper}>
        <div className={style.content}>
          <div className={style.textWrapper}>
            <h1 className={style.title}>{pageContext.title}</h1>
            <h3 className={style.excerpt} dangerouslySetInnerHTML={{__html: pageContext.excerpt}} />
          </div>
          {
            image && <GatsbyImage image={image} alt='image'/>
          }
          <div className={style.textWrapper}>
            <div dangerouslySetInnerHTML={{__html: pageContext.content}} className={style.info}/>
          </div>
          <div className={style.cta}>
            <div>Tag kontakt nu for et uforpligtende tilbud</div>
            <Link to='/kontakt'>
              <button className={style.ctaButton}>
                Send Besked
              </button>
            </Link>
          </div>
        </div>
      </div>

    </Layout>
  )
}

export default ydelseTemplate;
